.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* App.css */

/* App.css */
/* App.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: #343a40;
}

h2 {
  font-size: 20px;
  margin: 20px 0;
  text-align: left;
  color: #343a40;
  border-bottom: 2px solid #d1e9fe; /* Svak blå strek under overskriften */
  padding-bottom: 5px;
}

.form-group {
  margin-bottom: 20px; /* Økt avstand mellom feltene */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #495057;
}

.form-group select {
  width: 40%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  color: #495057;
}

.form-group select:focus {
  border-color: #80bdff;
  outline: none;
}

input[type="text"], input[type="date"] {
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  color: #495057;
}

input[type="text"]:read-only, input[type="date"]:read-only {
  background-color: #e9ecef;
}

button {
  display: inline-block;
  padding: 8px 16px;  /* Redusert padding */
  font-size: 14px;    /* Redusert fontstørrelse */
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  width: auto;  /* Gjør knappen mindre, ikke full bredde */
}

button:hover {
  background-color: #0056b3;
}


.suggestions-list {
  margin-top: 5px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  list-style-type: none;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f8f9fa;
}

.undertext {
  font-size: 13px;
  text-align: left;
  color: #2f2f2fa1;
}



/* Legg til denne CSS-en i din index.css fil eller en relevant CSS-fil */
@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.loader {
  display: inline-block;
  margin-left: 5px;
}

.loader-dot {
  animation-name: blink;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: black;
  margin: 0 3px;
}

.loader-dot:nth-child(1) {
  animation-delay: 0s;
}
.loader-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.loader-dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* GPT CHAT css slutt*/



/* CSS for Metadata Modal */
.metadata-modal {
  position: fixed; /* Plasser modalvinduet fast i forhold til visningsvinduet */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(218, 213, 213);
  padding: 1px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%; /* Maks bredde for modalvinduet */
  max-height: 80%; /* Maks høyde for modalvinduet */
  overflow: auto; /* Legg til rullefelt for innhold som ikke passer */
  z-index: 1000; /* Sørg for at modalvinduet ligger øverst */
}

.metadata-content {
  background-color: rgb(249, 247, 247);
  padding: 20px;
  max-width: 900px;
  text-align: center;

}

.metadata-text {
  text-align: left;
  white-space: pre-wrap; /* Bevarer innrykk og linjeskift */
  overflow-wrap: break-word; /* Bryter lange ord */
  max-width: 100%; /* Maks bredde for tekstcontaineren */
}



.form-label-button-container {
  display: flex;  /* Bruk Flexbox for å arrangere elementene på samme linje */
  align-items: center;  /* Juster elementene vertikalt i midten */
  justify-content: space-between; /* Plasser knapp og tekst på motsatte sider */
  margin-bottom: 10px;  /* Legg til litt avstand under */
}

.knapp {
  display: inline-block;
  padding: 4px 4px;  /* Redusert padding */
  font-size: 18px;  /* Større fontstørrelse for å gjøre prikkene tydelige */
  /*font-weight: bold;/* Svarte prikker */
  color: #000000;  /* Svarte prikker */
  background-color: transparent;  /* Gjør bakgrunnen gjennomsiktig */
  border: none;  /* Fjern knappens kant */
  border-radius: 50%;  /* Gjør knappen rund */
  cursor: pointer;
  margin-top: 0px;
  width: auto;  /* Gjør knappen mindre, ikke full bredde */
}

.knapp:hover {
  background-color: #8a8a8a;
}


.knapp::before {
  content: "•••";  /* Tre prikker som knappens innhold */
}
